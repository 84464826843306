<template>
  <v-container fluid fill-height>
    <v-row no-gutters  align="center" justify="center">
      <v-col cols="12" class="py-0" xs="12" sm="8" md="4">
        <v-form>
          <v-card elevation="6">
            <v-toolbar flat>
              <v-toolbar-title class="subtitle-1">Вкажіть логін аби скинути пароль</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="py-1 px-3">
              <v-text-field
                class="mt-3"
                prepend-icon="mdi-account"
                outlined
                v-model="username"
                label="Логін"
                type="text"
                autocomplete="username">
              </v-text-field>
            </v-card-text>
            <v-card-actions class="text-md-right text-xs-right px-3 pt-1 pb-3">
              <v-spacer/>
              <v-btn
                :disabled="!username || disabled"
                type="submit"
                class="text-md-right"
                small
                color="success"
                @click.prevent="sendResetPassword"
              >Скинути пароль
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UserAPI from '@/api/user'

  export default {
    name: 'ResetPasswordMessage',
    data() {
      return {
        username: '',
        disabled: false,
        timeout: 0,
      }
    },
    methods: {
      async sendResetPasswordAPI() {
          try {
              await UserAPI.sendResetPasswordOnEmail(this.username.toLowerCase());
              return true;
          } catch (e) {
              return false
          }
      },
      changeDisable() {
          this.disabled = false;
      },
      setDisableTimeout() {
          this.timeout = setTimeout(this.changeDisable, 60 * 1000)
      },
      deleteDisableTimeout() {
          clearTimeout(this.timeout);
          this.timeout = 0;
      },
      sendResetPassword() {
          if (this.username) {
              let success = this.sendResetPasswordAPI();

              if (success) {
                  this.$snackbar("Вам на email відправлено посилання на зміну пароля", 'success');
                  this.disabled = true;
                  this.setDisableTimeout();
              } else {
                  this.$snackbar("Помилка відправлення листа. Спробуйте ще раз", 'error');
                  this.disabled = false;
                  this.deleteDisableTimeout();
              }
          } else {
              this.$snackbar("Введіть логін", 'error')
          }
      }
    },
    destroyed() {
        this.deleteDisableTimeout();
    }
  }
</script>

<style scoped lang="scss">
</style>
